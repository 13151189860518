<template>
  <div>
    <!-- edit profile dialog -->
  <v-dialog
        v-model="isQuestionnaireOptionsDialogOpen"
        max-width="720px"
        @click:outside="$emit('update:is-questionnaire-options-dialog-open',false)"
      >
        <v-card class="py-8">
          <v-card-title class="justify-center text-h5 px-5">
            Select your option for building your questionnaire
          </v-card-title>
            <v-card-text class="text-center mt-n2 px-5" v-if="localProjectData.template">
            You have the choice between starting from a templated questionnaire or a past project questionnaire:
          </v-card-text>
            <v-card-text class="text-center mt-n2 px-5" v-else>
            You have the choice between starting from scratch or a past project questionnaire.
          </v-card-text>
          
          <v-card-text class="d-flex align-center flex-wrap flex-sm-nowrap mt-5 px-15">
              <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="localProjectData.templates && localProjectData.templates.length > 0"
                  >
                    <v-card
                      outlined
                      class="step-cursor"
                      v-for="template in localProjectData.templates" :key="template.id"
                      @click.native="$emit('build', true, template.id)"
                    >
                        <v-card-title>
                          {{ template.name }}
                        </v-card-title>
                    </v-card>
                    
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-else
                  >
                    <v-card
                      outlined
                      class="step-cursor"
                      @click.native="$emit('build', true)"
                    >
                      <v-card-title>From Scratch</v-card-title>
                    </v-card>
                    
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                  
                  <v-form
                      @submit="getQuestionnaire($event)"
                  >
                    <v-text-field
                      v-model="searchProject"
                      :value="searchQuestionnaireTrigger && !questionnaireLoading && questionnaireOptions.length? 'No Past Quest. Found' : ''"
                      label="Import from Past Project Search"
                      outlined
                      :loading="questionnaireLoading"
                      :append-icon="icons.mdiSend"
                      @click:append="getQuestionnaire"
                      @click:append-outer="getQuestionnaire"
                      :disabled="searchQuestionnaireTrigger && !questionnaireLoading && questionnaireOptions.length === 0"
                      hide-details
                    >

                    </v-text-field>
                    <!-- <v-card
                      outlined
                      class="step-cursor"
                      :loading="questionnaireLoading"
                      @click.native="getQuestionnaire"
                      :disabled="searchQuestionnaireTrigger && !questionnaireLoading && questionnaireOptions.length === 0"
                    >
                      <v-card-title v-if="searchQuestionnaireTrigger && !questionnaireLoading && questionnaireOptions.length === 0">No Past Quest. Found</v-card-title>
                      <v-card-title v-else>Import from Past Project</v-card-title>
                    </v-card> -->
                    </v-form>
                  </v-col>
                 
              </v-row>
          </v-card-text>
          
          
          
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="isPastProjectQuestionnaireDialog"
        persistent
        max-width="720px"
      >
        
      <v-card >
          <v-card-title class="justify-center text-h5 px-5 pt-8">
            Build your questionnaire from past projects
          </v-card-title>
          <v-card-text class="text-center mt-n2 px-5">
            Pick and choose which questions from whichever projects you want to use for your questionnaire.
          </v-card-text>
          <v-card-text>
            <v-form
                :ref="`questionnaireForm`"
            >
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-data-table
                v-model="selected"
                :headers="questionnaireHeaders"
                :group-by="groupBy"
                :items="questionnaireOptions"
                :search="search"
                ref="projectTable"
                item-key="id"
                show-select
                dense
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`header.data-table-select`]></template>
                
                <template v-slot:[`header.name`]="{header}">
                  <span class="v-label">
                    {{ header.text.toUpperCase() }}
                  </span>
                </template>
                <template v-slot:[`header.title`]="{header}">
                  <span class="v-label" :key="header.title">
                  </span>
                </template>
                <template v-slot:[`group.header`]="{group, items, toggle, isOpen}">
                  <th colspan="3"
                  >
                    <td class="text-start">
                      <v-checkbox
                        :label="items[0].job"
                        :value="group"
                        v-model="selectedJob" 
                        hide-details="auto"
                        class="mt-0"
                        :indeterminate="false"
                        :rules="selected.find(it => it.job === items[0].job) !== undefined ? [noDups] : []"
                      ></v-checkbox>
                    </td>
                    <td class="text-end">
                      <v-btn
                        :ref="group"
                        icon
                        small
                        @click="toggle"
                        :data-open="isOpen"
                      >
                        <v-icon v-if="isOpen">{{ icons.mdiMinus }}</v-icon>
                        <v-icon v-else>{{ icons.mdiPlus }}</v-icon>
                      </v-btn>
                    </td>
                
                  </th>
                    
                    
                </template>

                
                <template #[`item.title`]="{item}">
                  <div class="d-flex flex-column ms-3" v-if="item.title">
                    <span class="text--primary">{{ item.title.en }}</span>
                  </div>
                </template>

              </v-data-table>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-right">
            <v-btn
              text
              @click="isPastProjectQuestionnaireDialog = false"
              class="mr-4"
            >Cancel</v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  @click="next(false)"
                  v-bind="attrs"
                  v-on="on"
              class="mr-4"
                >
                  Only use questions
                </v-btn>
              </template>
              <span>Only selected questions will be in the new questionnaire.</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="warning"
                  @click="next(true)"
                  v-bind="attrs"
                  v-on="on"
              class="mr-4"
                >
                  Replace questions to template
                </v-btn>
              </template>
              <span>Selected questions will be replaced in the template as a new questionnaire</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
  
</template>
  <script>
  import { ref, watch, getCurrentInstance, onMounted } from '@vue/composition-api'
  // import ProjectIntercoList from '../project-interco-list/ProjectIntercoList.vue'
  import store from '@/store'
  import useSurvey from './useSurvey'
  import {
    required,
  } from '@core/utils/validation'

  import {
    mdiMinus,
    mdiPlus,
    mdiMagnify,
    mdiSend
  } from '@mdi/js'
  
  export default {
    props: {
      isQuestionnaireOptionsDialogOpen: {
        type: Boolean,
        required: true,
      },
      projectData: {
        type: Object,
        required: true
      }
    },
  
    setup(props, { emit }) {

      const vm = getCurrentInstance().proxy
      const isPastProjectQuestionnaireDialog = ref(false)
      const searchQuestionnaireTrigger = ref(false)
      const localProjectData = ref({})
      const questionnaireHeaders = [
        { text: 'Project', value: 'job', align: 'start', sortable: false, filterable:true},
        { text: 'Project / Question', value: 'name', align: 'start', sortable: false, filterable:true},
        { text: 'Text', value: 'title', align: 'start', sortable: false, filterable:true},
      ]
      const selected = ref([])
      const groupBy = ['job']
      const selectedJob = ref([])
      const search = ref('')
      const searchProject = ref('')

      const resetProjectData = () => {
        isPastProjectQuestionnaireDialog.value = false
        localProjectData.value = JSON.parse(JSON.stringify(props.projectData))
        selected.value = []
        questionnaireOptions.value = []
        searchQuestionnaireTrigger.value = false
        // isPastProjectQuestionnaireDialog.value = false
      }

      const {
        addProjectQuestionnaire,
        fetchQuestionnaires,
        questionnaireLoading,
        questionnaireOptions,
          surveyTemplate,
          fetchTemplate,
      } = useSurvey()

      const getQuestionnaire = (event) => {
        if (event) {
          event.preventDefault()
        }
        fetchQuestionnaires({
          search:searchProject.value
        })
        searchQuestionnaireTrigger.value = true
      }

      const next = async (useTemplate) => {
        
        const isStepValid = vm.$refs['questionnaireForm'].validate()
        if (!isStepValid){
          Object.keys(vm.$refs).forEach(k => {
              if (vm.$refs[k] && vm.$refs[k].$attrs['data-open']) {
                vm.$refs[k].$el.click()
              }
          })

        } else {
          let payload = {
            job_number: localProjectData.value.job_number,
            country: localProjectData.value.country.value
          }
          if (useTemplate){
            const templateId = localProjectData.value.service_type.survey_template
            
            if (templateId !== undefined){
              await fetchTemplate({id:templateId})
              
              payload = {
                ...payload,
                ...reconstructSurvey(selected.value, surveyTemplate.value.template)
              }
            } else {
              payload = {
                ...payload,
                ...reconstructSurvey(selected.value)
              }
            }
          } else {
            payload = {
              ...payload,
              ...reconstructSurvey(selected.value)
            }
          }
          
          addProjectQuestionnaire(payload)
        }
        
      }

      
    const reconstructSurvey = (data, template=null) => {
      
      let pages = []
      let sections = {}
      let elements = {}

      data.map(element => {
          const sectionName = element.section
          const sectionDesc = element.sectionDesc
          const sectionTitle = element.sectionTitle

          delete element.section
          delete element.sectionDesc
          delete element.sectionTitle
          delete element.job
          delete element.id
          if (sections[sectionName] !== undefined) {
            sections[sectionName].elements.push(element)
          } else {
            sections[sectionName] = {
              name: sectionName,
              title: sectionTitle,
              description: sectionDesc,
              elements: [element]
            }
            elements[sectionName] = {}
          }
          elements[sectionName][element.name] = element

        
      })
      
      if (template) {
        pages = template.pages.map(section => {
          if (section.elements !== undefined) {
            let localElements = section.elements.map(element => {
              if (elements[section.name] && elements[section.name][element.name] !== undefined) {
                element = elements[section.name][element.name]
                delete elements[section.name][element.name] 
              }
              return element
            })
            if (elements[section.name] !== undefined) {
              Object.values(elements[section.name]).forEach(value => {
                localElements.push(value)
              })
            }
            section.elements = localElements
            return section
          }
          
          return section
        })
        template.pages = pages
        return template
      } else {
        
        Object.values(sections).forEach( (value) => {
            pages.push(value)
          })

        return {
            "title": {en: "New Questionnaire"},
            pages
          }
        }

      }

      watch(
        () => props.projectData,
        () => {
          resetProjectData()
        }
      )

      watch(
        selectedJob,
        (node_new, node_old) => {
          if(node_new.length < node_old.length) {
            let dif = node_old.filter(x => !node_new.includes(x))[0] 
            selected.value = selected.value.filter(item => { return !Object.values(item).includes(dif) })
          } else if (node_new.length > node_old.length) {
            questionnaireOptions.value.forEach(item => {
              if(Object.values(item).includes(node_new.at(-1))) {
                selected.value.push(item)
              }
            })
          } else if (node_new.length == node_old.length) {
              return selected.value
          }
        }
      )

      const lookup = (values) => {
        return values.reduce((a, e) => {
          a[e.name] = ++a[e.name] || 0;
          return a;
        }, {})
      }

      watch(
        selected,
        (selected_new, selected_old) => {
          if(selected_new.length < selected_old.length) {
            let dif_NODE = selected_old.filter(x => !selected_new.includes(x))[0]['job']          
            let arr_NODEs = []
            selected_new.forEach(item => { arr_NODEs.push(item['job']) })
            
            if(!arr_NODEs.includes(dif_NODE)) { 
              selectedJob.value = selectedJob.value.filter(item => item != dif_NODE)
            } 
            else {
              const index = selectedJob.value.indexOf(dif_NODE)
              selectedJob.value.splice(index, 1)
              selectedJob.value.push(selected_new.at(-1)['job'])
            }
          } else if(selected_new.length > selected_old.length) {
            if(!selectedJob.value.includes(selected_new.at(-1)['job'])) {
              selectedJob.value.push(selected_new.at(-1)['job'])
            } else {
              const index = selectedJob.value.indexOf(selected_new.at(-1)['job'])
              
              selectedJob.value.splice(index, 1)
              selectedJob.value.push(selected_new.at(-1)['job'])
            }
          }
          
        }
      )

      watch(
        () => questionnaireOptions.value,
        (newVal, oldVal) => {
            if (newVal.length > 0) {
              isPastProjectQuestionnaireDialog.value = true
              setTimeout(() => {
                
                let table = vm.$refs.projectTable
                let keys = Object.keys(table.$vnode.componentInstance.openCache)
                keys.forEach(x => {
                  table.$vnode.componentInstance.openCache[x] = false;
                })
              }, 100)
            }
        }
      )

      
      const noDups = (value) => {
            const duplicates = selected.value.filter(e => lookup(selected.value)[e.name])
          return !selected.value.filter(e => {
            return lookup(selected.value)[e.name] && value.includes(e.job)}).length > 0 || `Duplicated questions selected: ${[...new Set(duplicates.map(dup => dup.name))].join(', ')}`
        
  }
      return {
        isPastProjectQuestionnaireDialog,
        search,
        searchProject,
        selected,
        selectedJob,
        localProjectData,
        groupBy,
        questionnaireLoading,
        searchQuestionnaireTrigger,
        questionnaireOptions,
        getQuestionnaire,
        questionnaireHeaders,
        noDups,
        next,

        validators: {
          required
        },
        icons: {
          mdiMinus,
          mdiPlus,
          mdiMagnify,
          mdiSend
        }
      }
    },
  }
</script>


<style lang="css" scoped>

.step-cursor {
  cursor: pointer;
}

</style>